import { useEffect, useState } from 'react';
import './style.scss';

function Loader({ isLoading }) {
  const [unit, setUnit] = useState(window.innerWidth > window.innerHeight ? 'vh' : 'vw');
  const handleWindowResize = () => {
    setUnit(window.innerWidth > window.innerHeight ? 'vh' : 'vw');
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <div className={`component--loader ${isLoading ? 'active' : ''}`}>
      <div className="loader-0" style={{ width: `30${unit}`, height: `30${unit}` }} />
      <div className="loader-1" style={{ width: `20${unit}`, height: `20${unit}`, left: `-10${unit}` }} />
      <div className="loader-2" style={{ width: `10${unit}`, height: `10${unit}` }} />
    </div>
  );
}

export default Loader;
