// Dependencies
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

// Components
import Footer from './components/Footer';
import Navigation from './components/Navigation';

// Pages
import About from './pages/About';
import Detail from './pages/Detail';
import Work from './pages/Work';

// Style
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <AppContents />
      <Footer />
    </BrowserRouter>
  );
}

function AppContents() {
  const location = useLocation();
  const [ displayLocation, setDisplayLocation ] = useState(location);
  const [ pageTransitionState, setPageTransitionState ] = useState('fade-in');

  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) {
      setPageTransitionState('fade-out'); 
    }
  }, [location, displayLocation]);

  return (
    <div
      className={`app--page-transition ${pageTransitionState}`}
      onAnimationEnd={() => {
        if (pageTransitionState === 'fade-out') {
          setPageTransitionState('fade-in');
          setDisplayLocation(location);
        }
      }}
    >
      <Routes location={displayLocation}>
        <Route path="/" element={<Work />}></Route>
        <Route path="/work" element={<Work />}></Route>
        <Route path="/work/:id" element={<Detail />}></Route>
        <Route path="/about" element={<About />}></Route>
      </Routes>
    </div>
  );
}

export default App;
