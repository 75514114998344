
import './style.scss';

function Image({ url }) {
  return (
    <div
      className="component--image"
      style={{ backgroundImage: `url(${url || 'https://via.placeholder.com/320x320'})` }}
    >
    </div>
  );
}

export default Image;
