import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DataNotFound from '../../components/DataNotFound';
import Loader from '../../components/Loader';

import './style.scss';

const apiUrlBase = process.env.NODE_ENV === 'production' ? '/cms' : `http://localhost:4200/cms`;

function Work() {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({ list: [] });
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      try {
        const response = await axios.get(`${apiUrlBase}/work`);
        setData(response.data);
        setIsLoading(false);
        clearTimeout(timeout);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        clearTimeout(timeout);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="page--work">
      <Loader isLoading={isLoading} />
      {!isLoading && !data.list?.length &&
        <DataNotFound />
      }
      <div className="grid">
        {data.list?.length > 0 &&
          data.list
            .filter((item) => { return item.fields.show; })
            .map((item, index) => {
              return (
                <div className="page--work__project col-3_lg-4_sm-6_xs-12" key={index}>
                  <Link to={`/work/${item.fields.detailReference[0]}`}>
                    <div className="page--work__project__thumbnail" style={{ backgroundImage: `url(${item.fields.thumbnail[0].url})` }}></div>
                    <p className="bold page--work__project__title">{item.fields.title}</p>
                    <p>{item.fields.overview}</p>
                  </Link>
                </div>
              );
            }
          )
        }
      </div>
    </div>
  )
}

export default Work;
