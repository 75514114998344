/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

import './style.scss';
import { useRef } from 'react';

const vertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
  }
`;
const fragmentShader = `
  uniform float time;
  uniform sampler2D tDiffuse;
  uniform vec2 resolution;
  varying vec2 vUv;
  uniform vec2 uMouse;

  float circle(vec2 uv, vec2 disc_center, float disc_radius, float border_size) {
    uv -= disc_center;
    uv*=resolution;
    float dist = sqrt(dot(uv, uv));
    return smoothstep(disc_radius+border_size, disc_radius-border_size, dist);
  }

  void main()  {
    vec2 newUV = vUv;
    float c = circle(vUv, uMouse, 0.0, 0.5);
    float r = texture2D(tDiffuse, newUV.xy += c * (0.1 * .2)).x;
    float g = texture2D(tDiffuse, newUV.xy += c * (0.1 * .65)).y;
    float b = texture2D(tDiffuse, newUV.xy += c * (0.1 * .85)).z;
    vec4 color = vec4(r, g, b, 1.);

    gl_FragColor = color;
  }
`;

const Contents = ({ resolution, uMouse }) => {
  const texture = useLoader(THREE.TextureLoader, './assets/images/portrait.jpeg');
  const mesh = useRef();
  const material = useRef();

  const uniforms = useMemo(() => {
    return {
      tDiffuse: { value: texture },
      resolution: { value: resolution },
      uMouse: { value: uMouse }
    }
  }, []);

  if (material.current) {
    material.current.uniforms.uMouse.value = new THREE.Vector2(uMouse.x, uMouse.y);
    material.current.uniforms.resolution.value = new THREE.Vector2(1., resolution.y);
  }

  return (
    <mesh ref={mesh}>
      <boxGeometry args={[0.663, 1, 0.001]} />
      <shaderMaterial
        ref={material}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        uniforms={uniforms}
      />
    </mesh>
  );
};

const InteractivePortrait = () => {
  const [uMouse, setUMouse] = useState(new THREE.Vector2(-10, -10));
  const [resolution, setResolution] = useState(new THREE.Vector2(1., window.innerHeight/(window.innerWidth*2)));
  const handleMouseMove = (e) => {
    const targetRect = e.target.getBoundingClientRect();
    const rx = (e.clientX - targetRect.x) / targetRect.width;
    const ry = (e.clientY - targetRect.y) / targetRect.height;
    setUMouse(new THREE.Vector2( rx, 1 - ry ));
    setResolution(new THREE.Vector2(1., window.innerHeight / (window.innerWidth*2)));
  }

  return (
    <div className="component--interactive-portrait" onMouseMove={(e) => { handleMouseMove(e) }}>
      <Canvas className="component--interactive-portrait__canvas" camera={{ position: [0, 0, 0.5]}}>
        <ambientLight intensity={0.5} />
        <directionalLight />
        <Contents uMouse={uMouse} resolution={resolution} />
        {/* <OrbitControls /> */}
      </Canvas>
    </div>
  )
}

export default InteractivePortrait;