import './style.scss';

function DataNotFound() {
  return (
    <div className="component--data-not-found">
      <h5>Could not load data</h5>
      <br />
      <p>Please try reloading, or visit the site later.</p>
      <br />
      <a className="styled" href="/#" onClick={() => { window.location.reload(); }}>Reload</a>
    </div>
  );
}

export default DataNotFound;
