import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './style.scss';

function Navigation() {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);
  const location = useLocation();
  
  return (
    <div className="component--navigation">
      <div className="component--navigation__container">
        <Link to="/">
          <h2>Kaz Saegusa</h2>
        </Link>
        <div className="component--navigation__items--desktop">
          <Link className={`component--navigation__item ${location.pathname !== '/about' ? 'active' : ''}`} to="/">Work</Link>
          <Link className={`component--navigation__item ${location.pathname === '/about' ? 'active' : ''}`} to="/about">About</Link>
        </div>
        <div className={`component--navigation__items--mobile ${isMenuOpen ? 'open' : 'closed'}`}
          onClick={() => { setIsMenuOpen(!isMenuOpen) }}
        >
          <div className="component--navigation__items--mobile__menu-container">
            <div className="component--navigation__items--mobile__menu-bar"></div>
            <div className="component--navigation__items--mobile__menu-bar"></div>
            <div className="component--navigation__items--mobile__menu-bar"></div>
          </div>
          <div className={`component--navigation__items--mobile__menu-list ${isMenuOpen ? 'open' : 'closed'}`}>
            <Link className={`component--navigation__item ${location.pathname !== '/about' ? 'active' : ''}`} to="/">Work</Link>
            <Link className={`component--navigation__item ${location.pathname === '/about' ? 'active' : ''}`} to="/about">About</Link>  
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
