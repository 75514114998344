/* eslint-disable no-useless-escape */
const WithLinks = ({ content }) => {
  if (!content) return ( <></> );
  const linkedText = content ? content.match(/\[.*?\]/g) : '';
  if (linkedText) {
    let contentOrg = content;
    const tags = [];
    linkedText.forEach(linkedTextItem => {
      tags.push(<span>{contentOrg.substring(0, contentOrg.indexOf(linkedTextItem))}</span>);
      const link = content.substring(content.indexOf(linkedTextItem) + linkedTextItem.length).match(/\(.*?\)/)[0].replace(/[()]/g, '');
      tags.push(<a className="external" href={link}>{linkedTextItem.replace(/[\[\]']+/g, '')}</a>);
      contentOrg = contentOrg.substring(contentOrg.indexOf(link) + link.length + 1);
    });
    tags.push(<span>{contentOrg}</span>);
    return (
      <p>{tags.map(item => {
        return item;
      })}</p>
    );
  } else {
    return <p>{content}</p>;
  }
  
};

export default WithLinks;