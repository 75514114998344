// Components
import InteractivePortrait from '../../components/InteractivePortrait';

import './style.scss';

// import { parseTags } from '../../utils';

// const apiUrlBase = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:4200';

const AboutMe = () => {
  return (
    <div>
      <h4>Hello, I'm Kaz.</h4>
      <br/>
      <p>I am a design technologist, bridging design and technology to create experiences at quality and speed. </p>
      <br/>
      <p>I am currently based in San Francisco, CA as a Director of Design Technology at frog design SF studio, where I lead/contribute to client facing initiatives for experience design and development, product delivery, design research, interaction design and etc. in variety of industries</p>
      <br/>
      <p>Sometimes I design digital or physical experiences to envision ideas into preliminary artifacts. Sometimes I run a design research to learn more about users. And sometimes I code to deliver an experience prototype, exhibition, product or whatever the form it takes. I believe all of these bits are important in the creative process for any experience design and development, and I’ve been striving to be a bridge among them both in my professional and personal projects. </p>
      <br/>
      <p>Some of my toolkit comfort zones as of now are: nodeJS, React/Angular, AWS, oFx/p5, Python, RPi/Arduino and electronics, Figma, Design Research, etc... and I do enjoy picking new things up quickly as well.</p>
      <br/>
      <p>Feel free to drop a line and say hi to me!</p>
      <br/>
      <h6>Email:</h6>
      <div>
        <a className="styled" href="mailto:info@kazsaegusa">info [at] kazsaegusa.com</a>
      </div>
      <br/>
      <h6>Resume:</h6>
      <div>
        <a className="styled" href="/assets/docs/resume.pdf" target="_blank">Review Resume</a>
      </div>
    </div>
  );
}

function About() {
  return (
    <div className="page--about">
      <div className="grid">
      <div className="col-12 visibility--mobile">
          <AboutMe />
        </div>
        <div className="col-6_sm-12" id="interactive-portrait__container">
          {/* <div className="page--about__portrait-container"></div>   */}
          <InteractivePortrait />
        </div>
        <div className="col-6 visibility--desktop">
          <AboutMe />
        </div>
      </div>
    </div>
  )
}

export default About;
