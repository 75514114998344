
import './style.scss';

function Image({ url }) {
  return (
    <div className="component--section-divider">
      {/* <div className="component--section-divider__line"></div> */}
    </div>
  );
}

export default Image;
