
import './style.scss';

function Footer() {
  return (
    <div className="component--footer">
      <div className="component--footer__container">
        <div className="compnent--footer__copyrights">
          <p>© Kaz Saegusa, {new Date().getFullYear()} - all rights reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
