import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import DataNotFound from '../../components/DataNotFound';
import Image from '../../components/Image';
import SectionDivider from '../../components/SectionDivider';
import Loader from '../../components/Loader';
import WithLinks from '../../components/WithLinks';

import './style.scss';

const apiUrlBase = process.env.NODE_ENV === 'production' ? '/cms' : `http://localhost:4200/cms`;

function Detail() {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      try {
        const response = await axios.get(`${apiUrlBase}/detail/${id}`);
        setData(response.data);
        setIsLoading(false);
        clearTimeout(timeout);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        clearTimeout(timeout);
      }
    }
    fetchData();
  }, [id]);

  return (
    <div className="page--detail">
      <Loader isLoading={isLoading} />
      {!isLoading && (!data || !data.fields) &&
        <DataNotFound />
      }
      {data && data.fields &&
        <div className="grid">

          {/* Hero - Video Url */}
          {data.fields.videoUrl &&
            <div className="col-12">
              <div className="page--detail__hero--video__container">
                <iframe
                  title={`Video for ${data.fields.title}`}
                  className="page--detail__hero--video__iframe"
                  src={data.fields.videoUrl}
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          }

          {/*Hero - Video File */}
          {data.fields.heroVideo &&
            <div className="col-12">
              <div className="page--detail__hero--video__container">
                <video
                  className="page--detail__hero--video__iframe"
                  src={data.fields.heroVideo[0].url}
                  controls
                />
              </div>
            </div>
          }

          {/* Hero - Image */}
          {data.fields.heroImage &&
            <div className="col-12">
              <div 
                className="page--detail__hero--image__container"
                style={{ backgroundImage: `url(${data.fields.heroImage[0].url})` }}
              >
              </div>
            </div>
          }

          {/* Summary */}
          <div className="col-12">
            <h4 className="page--detail__project-title">{data.fields.title}</h4>
          </div>

          <div className="col-12 visibility--mobile">
            <p>{data.fields.overview}</p>
          </div>

          <div className="col-6_sm-12">
            
            <div>
              <p className="bold">Studio:</p>
              <p>{data.fields.studio}</p>
              <br/>
            </div>

            {data.fields.client && <div>
              <p className="bold">Client:</p>
              <p>{data.fields.client}</p>
              <br/>
            </div>}
            
            {/* {data.fields.year && <div>
              <p className="bold">Year: </p>
              <p>{data.fields.year}</p>
              <br/>
            </div>} */}

            <div>
              <p className="bold">Responsibility:</p>
              <WithLinks content={data.fields.responsibility} />
            </div>
          </div>

          <div className="col-6 visibility--desktop">
            <WithLinks content={data.fields.overview} />
          </div>

          {/* Process */}
          {(data.fields.process_text || data.fields.process_image_hero || data.fields.process_images) &&
            <div className="col-12">
              <SectionDivider />
              <h5>Process</h5>
            </div>
           }
          {data.fields.process_text &&
            <div className="col-4_md-6_sm-12">
              <WithLinks content={data.fields.process_text} />
            </div>
          }
          {data.fields.process_image_hero &&
            <div className="col-8_md-6_sm-12 page--detail__hero-image">
              <Image url={data.fields.process_image_hero[0].url} />
            </div>
          }
          {!data.fields.process_image_hero && data.fields.process_images &&
            <div className="col-8_sm-12">
              <div className="grid-noGutter">
                {data.fields.process_images && data.fields.process_images.map((image, index) => {
                  return (
                    <div className="col-6_sm-12" key={index}>
                      <Image url={image.url} />
                    </div>
                  )
                })}
              </div>
            </div>
          }
          {data.fields.process_image_hero && data.fields.process_images &&
            <div className="col-12">
              <div className="grid-noGutter">
                {data.fields.process_images && data.fields.process_images.map((image, index) => {
                  return (
                    <div className="col-6_sm-12" key={index}>
                      <Image url={image.url} />
                    </div>
                  )
                })}
              </div>
            </div>
          }

          {/* Technology */}
          {(data.fields.technology_text || data.fields.technology_image_hero || data.fields.technology_images) &&
            <div className="col-12">
              <SectionDivider />
              <h5>Technology</h5>
            </div>
           }
          {data.fields.technology_text &&
            <div className="col-4_md-6_sm-12">
              <WithLinks content={data.fields.technology_text} />
            </div>
          }
          {data.fields.technology_image_hero &&
            <div className="col-8_md-6_sm-12">
              <Image url={data.fields.technology_image_hero[0].url} />
            </div>
          }
          {!data.fields.technology_image_hero && data.fields.technology_images &&
            <div className="col-8_sm-12">
              <div className="grid-noGutter">
                {data.fields.technology_images && data.fields.technology_images.map((image, index) => {
                  return (
                    <div className="col-6_sm-12" key={index}>
                      <Image url={image.url} />
                    </div>
                  )
                })}
              </div>
            </div>
          }
          {data.fields.technology_image_hero && data.fields.technology_images &&
            <div className="col-12">
              <div className="grid-noGutter">
                {data.fields.technology_images && data.fields.technology_images.map((image, index) => {
                  return (
                    <div className="col-6_sm-12" key={index}>
                      <Image url={image.url} />
                    </div>
                  )
                })}
              </div>
            </div>
          }

          {/* Outcome */}
          {(data.fields.outcome_text || data.fields.outcome_image_hero || data.fields.outcome_images) &&
            <div className="col-12">
              <SectionDivider />
              <h5>Outcome</h5>
            </div>
           }
          {data.fields.outcome_text &&
            <div className="col-4_md-6_sm-12">
              <WithLinks content={data.fields.outcome_text} />
            </div>
          }
          {data.fields.outcome_image_hero &&
            <div className="col-8_md-6_sm-12">
              <Image url={data.fields.outcome_image_hero[0].url} />
            </div>
          }
          {!data.fields.outcome_image_hero && data.fields.outcome_images &&
            <div className="col-8_sm-12">
              <div className="grid-noGutter">
                {data.fields.outcome_images && data.fields.outcome_images.map((image, index) => {
                  return (
                    <div className="col-6_sm-12" key={index}>
                      <Image url={image.url} />
                    </div>
                  )
                })}
              </div>
            </div>
          }
          {data.fields.outcome_image_hero && data.fields.outcome_images &&
            <div className="col-12">
              <div className="grid-noGutter">
                {data.fields.outcome_images && data.fields.outcome_images.map((image, index) => {
                  return (
                    <div className="col-6_sm-12" key={index}>
                      <Image url={image.url} />
                    </div>
                  )
                })}
              </div>
            </div>
          }

          {/* Back to Home */}
          <div className="col-12">
            <div className="page--detail__back-to-home">
              <Link to="/" className="styled">&larr; Back to home</Link>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Detail;
